import { addUserJWT, fetchWrapped, PostOptions } from '.';
import { puggyApi } from './endpoints';

const headers = {
  'Content-Type': 'application/json',
  Accepts: 'application/x.miab.product-chat',
  'Access-Control-Allow-Origin': '*',
};

const getMessage = async ({ message, chat_id, userJWT, chat_message_id, type }) => {
  const bodyObject = {
    message: message,
    chat_id,
    lang: 'nl',
  };
  if (type) bodyObject.type = type;
  return await fetchWrapped(
    `${puggyApi}/chat/ask/`,
    new PostOptions({
      headers: { ...addUserJWT(userJWT)({ ...headers, 'chat-message-id': chat_message_id }) },
      bodyObject,
    })
  );
};

const getHistoryMessage = async ({ chat_id, userJWT }) =>
  await fetchWrapped(
    `${puggyApi}/chat/history/`,
    new PostOptions({
      headers: { ...addUserJWT(userJWT)(headers) },
      bodyObject: {
        chat_id,
      },
    })
  );

const getGreetingMessage = async ({ userJWT }) =>
  await fetchWrapped(
    `${puggyApi}/chat/greeting/`,
    new PostOptions({
      headers: { ...addUserJWT(userJWT)(headers) },
    })
  );

export default { getMessage, getHistoryMessage, getGreetingMessage };
